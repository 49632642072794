import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold text-black">404: Not Found</h1>
      <p className="mt-6">You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/" className="text-gray-500 hover:text-gray-900 tont-medium underline mt-6">Go To Home Page</Link>
    </div>
  </Layout>
)

export default NotFoundPage
